<template>
    <p class="text-right">
			<mw-dialog 
				title="Add New Group"
				v-model="dialog"
				small
				submit-btn
				@submit="submit"
			>
				<template v-slot:button>
					<v-btn @click="dialog = true" color="primary">
						New Group
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<p>To add a new group, enter a name and a description of your group.</p>
					<v-text-field v-model="name" label="Name"></v-text-field>
					<v-textarea v-model="description" label="Description"></v-textarea>
					<v-autocomplete label="Select group members below" :items="users" multiple chips v-model="members"></v-autocomplete>
				</template>
			</mw-dialog>
		</p>
</template>
<script>
	import MwDialog from "@c/ui/MwDialog.vue";
	export default {
		name: "CreateGroup",
		data: () => {
			return {
				name: null,
				dialog: false,
				description: null,
				members: [],
				notes: null 
			};
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data
			},
			users() {
				return Object.values(this.$store.getters["users/respondents"]).map( user => ({text: user.name, value: user.id }))
			},
		},
		methods: {
			submit() {
				this.$store.dispatch("groups/insert", {
					name: this.name, 
					description: this.description,
					organisation: this.organisation.id,
					members: this.members, 
					status: "published"
				}).then( () => {
					this.dialog = false;
					this.name = null;
					this.members = [];
					this.description = null;
				})
			}
		},
		watch: {
		},
		components: {
			MwDialog,
		},
	};
</script>
