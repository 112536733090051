<template>
	<v-sheet>
        
        <v-row>

            <v-col cols="9">
                <v-container v-if="current.response" >
                    <v-card flat color="lightest" class="pa-5 mb-5">
                        
                    <poll-list-inner class="pa-2"></poll-list-inner>
                    </v-card>
                    <v-row class="mb-3">
                        <v-col>
                            <v-btn router-link to="/assessment" color="primary">View Section in Assessment</v-btn>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn color="success"  v-if="canPoll" @click="$root.$emit('newPoll');">New Poll</v-btn>
                        </v-col>
                    </v-row>
                    <new-poll v-if="canPoll"></new-poll>

                </v-container>
                <v-container v-else>
                    <p>
                        You can use this page to manage all of your polls in one place. To view existing polls, please first select an assessment section from the menu on the right. 
                    </p>
                    <p>For details on individual polls clic on the i for the poll you are interested in.</p>
                    <p>When available, you can add new polls by clicking on the “New Polls” button.</p>
                </v-container>

            </v-col>
            <v-col cols="3">
                <v-list class="poll-list-navigation">
                    <v-list-item 
                    v-for="item in order" 
                    @click="navigate(item)" 
                    :key="item.response" 
                    :class="{active: current.response == item.response}">
                        <v-list-item-content>

                        <v-list-item-title>
                            {{sections[item.section].name}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <span v-if="item.service">
                                {{categoryOptions[item.service].name}} 
                            </span>    
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        
	</v-sheet>
</template>
<style lang="less">
    .poll-list-navigation {
        border-left: 1px solid grey;
        .v-list-item__title {
            font-weight: bold;
            font-size: 0.9rem;
        }
        .v-list-item__subtitle {
            font-size: 0.8rem;
        }
        .theme--light.v-list-item.active {
            color: #124987 !important;
            background: lighten(#124987, 60% );
        }
    }
</style>

<script>
    import NewPoll from '@c/collaboration/NewPoll.vue';
    import PollListInner from './PollListInner.vue';
	export default {
		name: "PollList",
		data: () => {
			return {
			};
		},
		computed: {
            canPoll() {
                return this.$store.getters.canPoll 
            },
            current() {
                return this.$store.state.current;
            },
            sectionResponses() {
                return this.$store.state.sectionResponses.data
            },
            categoryOptions(){
                return this.$store.state.categoryOptions.data;
            },
            order(){
                const self = this;
                let order = this.$store.getters.order;
                return order.map( item => {
                    let sectionResponse = self.sectionResponses[item.response];
                    if( sectionResponse ){
                        return {
                            ...item,
                            service: sectionResponse.multiple ? sectionResponse.service : false
                        }
                    }
                })
            },
            sections( ){
                return this.$store.state.sections.data
            },
		},
		methods: {
            navigate({section, response}){
                this.$store.commit("GO_TO", {section, response})
            }
		},
		watch: {
            current() {
                this.$store.dispatch("polls/byResponse", this.current.response)
            }
        },
		components: {
            NewPoll,
            PollListInner
		},

	};
</script>
