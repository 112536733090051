<template>
	<v-sheet>
		<h2>{{ $t("collaboration.my_team") }}</h2>
		<p>This dashboard keeps you informed about the team working with you on completing the Digital Maturity Assessment, ongoing collaborations and the progress your colleagues are making with their tasks. </p>
        <v-tabs v-model="tab">
            <v-tab>People</v-tab>
            <v-tab>Groups</v-tab>
            <v-tab v-if="assessment">Polls</v-tab>
            <v-tab v-if="assessment">Delegations</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pt-5">
            <v-tab-item>
                <people-list></people-list>
            </v-tab-item>
            <v-tab-item>
                <group-list></group-list>
            </v-tab-item>
			<v-tab-item v-if="assessment">
				<poll-list></poll-list>
			</v-tab-item>
			<v-tab-item v-if="assessment">
				<delegations-list></delegations-list>
			</v-tab-item>
        </v-tabs-items>
	</v-sheet>
</template>

<style lang="less">
</style>
<script>
    import GroupList from '@c/team/GroupList.vue';
    import PeopleList from '@c/team/PeopleList.vue';
    import PollList from '@c/team/PollList.vue';
    import DelegationsList from '@c/team/DelegationsList.vue';
	export default {
		name: "MyTeam",
		data: () => {
			return {
                tab: 0
            };
		},
		computed: {
			assessment() {
				return this.$store.getters["assessment/id"];
			},
			responses(){
				return this.$store.state.sectionResponses.data
			}
		},
		methods: {
		},
		components: {
            PeopleList,
            GroupList,
			PollList, 
			DelegationsList
		},
		watch: {
			assessment(id) {
				if( id ){
					this.$store.dispatch("assessment/start", id );
				}
			}
		}, 
	};
</script>
